import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { Project } from '../../../../types/Project'
import { ArchiveBoxIcon } from '@heroicons/react/24/solid'
import Tooltip from '../../../UI/Tooltip/Tooltip'

type Props = { data: Project }

const Entry: React.FC<Props> = ({ data }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <a href={`/dashboard/projects/${data.uuid}`} className="flex w-full rounded-sm shadow-sm bg-white p-3 gap-2 hover:bg-lightGray">
      <div className="w-10/12">
        <div className="text-sm text-gray font-bold">{t.project.properties.name}</div>
        <div className="truncate">{data.name}</div>
      </div>
      <div className="w-2/12 flex justify-end items-end gap-3">
        {data.isArchived && (
          <Tooltip text={t.project.list.isArchived}>
            <ArchiveBoxIcon className="fill-gray w-5" />
          </Tooltip>
        )}
      </div>
    </a>
  )
}

export default Entry
