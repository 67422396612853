import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import { TimetrackerEntry, TimetrackerFilter, TimetrackerSummary } from '../../../types/Timetracker'

type Response = {
  pull: (projectUUID: string) => Promise<TimetrackerEntry[] | false>
  add: (data: TimetrackerEntry) => Promise<boolean>
  getSummary: (filter?: TimetrackerFilter) => Promise<TimetrackerSummary | false>
}

const useTimetracker = (): Response => {
  const createEntry = functional.use(Routes.TIMETRACKER_CREATE_ENTRY)
  const getEntries = functional.use(Routes.TIMETRACKER_GET_ENTRIES)
  const fetchSummary = functional.use(Routes.TIMETRACKER_GET_PROJECT_SUMMARY)

  const pull = async (projectUUID: string) => {
    const entries = await getEntries({ projectUUID })
    if (!!entries) {
      return entries
    } else {
      return false
    }
  }

  const add = async (data: TimetrackerEntry) => {
    const entry = await createEntry(data)
    if (entry) {
      return true
    } else {
      return false
    }
  }

  const getSummary = async (filter: TimetrackerFilter) => {
    const summary = await fetchSummary({ filter })
    if (summary) {
      return summary
    } else {
      return false
    }
  }

  return {
    pull,
    add,
    getSummary,
  }
}

export default useTimetracker
