import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { useState } from 'react'
import Modal from '../Modal/Modal'
import Button from '../Button/Button'

type Props = {
  title: string
  text: string
  callback: () => void
}

const Confirmation: React.FC<Props> = ({ title, text, callback }) => {
  const [open, setOpen] = useState<boolean>(false)
  const t = useSelector((s) => s[props.TRANSLATION])

  const toggle = () => setOpen(!open)

  const submit = () => {
    callback()
    setOpen(false)
  }

  return (
    <>
      <Button text={title} onClick={toggle} />
      <Modal noForm onConfirm={submit} show={open} cancelButtonText={t.generic.cancel} onClose={toggle} title={title}>
        <div className="flex flex-col gap-3 mt-3">{text}</div>
      </Modal>
    </>
  )
}

export default Confirmation
