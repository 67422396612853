import { useSelector } from 'react-redux'
import { useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import Modal from '../../../../UI/Modal/Modal'
import Button from '../../../../UI/Button/Button'
import props from '../../../../../redux/props'
import Routes from '../../../../../redux/routes'
import useToast, { Type } from '../../../../hooks/useToast'
import Input from '../../../../UI/Input/Input'

type Props = {
  uuid: string
  small?: boolean
}

const SendViaMail: React.FC<Props> = ({ uuid, small }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState(false)
  const sendMail = functional.use(Routes.SEND_PROTOCOL_VIA_MAIL)
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const [testReceiver, setTestReceiver] = useState('')

  const toggle = () => setOpen(!open)

  const start = async () => {
    setLoading(true)
    const status = await sendMail({ uuid, testReceiver })
    setLoading(false)
    if (!!status) {
      toast(t.UI.sendViaMail.success, Type.SUCCESS)
    } else {
      toast(t.UI.sendViaMail.error)
    }
  }

  return (
    <div>
      <Button small={small} text={t.UI.sendViaMail.cta} onClick={toggle} />
      <Modal onClose={toggle} show={open} loading={loading} onConfirm={start} title={t.UI.sendViaMail.title}>
        <Input label={t.UI.sendViaMail.testReceiver} required value={testReceiver} onChange={setTestReceiver} />
      </Modal>
    </div>
  )
}

export default SendViaMail
