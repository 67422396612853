import { functional } from '@think-internet/zeus-frontend-package'
import props from '../../../redux/props'
import { useDispatch, useSelector } from 'react-redux'
import { Invoke, ResponseFunctional } from './types'
import { setLocal } from '../../../redux/action/local'
import { useCallback, useEffect } from 'react'
import Routes from '../../../redux/routes'

const useAnyFunctional = <T>(prop: string, route: string, payload: Object = {}, enableFetching = true): ResponseFunctional<T> => {
  const dispatch = useDispatch()
  const anyFeature = functional.use(Routes[route])
  const data: T = useSelector((s) => s[props[prop]])
  const token = useSelector((s) => s[props.TOKEN])

  const pull: Invoke = useCallback(async () => {
    if (enableFetching) {
      const data = await anyFeature(payload)
      if (data) {
        dispatch(setLocal(props[prop], data))
      }
    }
  }, [anyFeature, dispatch, prop, payload, enableFetching])

  useEffect(() => {
    if (!data && !!token) {
      pull()
    }
  }, [data, token, pull])

  return [data, pull]
}

export default useAnyFunctional
