type Props = { text: string; children: React.ReactNode; className?: string; classNameInner?: string }

const Tooltip: React.FC<Props> = ({ text, children, className = '', classNameInner = '' }) => {
  return (
    <div className={`relative group w-fit cursor-pointer ${className}`}>
      <div
        className={`whitespace-nowrap text-sm hidden group-hover:block absolute left-1/2 -translate-x-1/2 bottom-full mb-1 bg-blue text-white shadow-sm rounded-sm py-1 px-2 z-10 ${classNameInner}`}
      >
        {text}
      </div>
      {children}
    </div>
  )
}

export default Tooltip
