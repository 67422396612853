import { getIcon } from 'material-file-icons'

type Props = {
  filename: string
}

const GetFileIcon: React.FC<Props> = ({ filename }) => {
  return (
    <div className="w-5">
      <div dangerouslySetInnerHTML={{ __html: getIcon(filename).svg }} />
    </div>
  )
}

export default GetFileIcon
