import { useState } from 'react'
import Input, { BaseInput } from '../Input/Input'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'

type Item = {
  value: any
}

type Props = BaseInput & { feature?: string; items?: Item[]; props: string[] }

const SuggestionInput: React.FC<Props> = ({ feature, props, value, onChange, invert = false, ...params }) => {
  const [items, setItems] = useState<Item[]>([])
  const runPropertySuggestion = functional.use(Routes.PROPERTY_SUGGESTION)
  const [enabled, setEnabled] = useState(false)

  const overwrite = (item: any) => () => {
    onChange(item.value)
    setItems([])
  }

  const updateInput = async (value: string) => {
    onChange(value)
    if (!!feature) {
      const result = await runPropertySuggestion({ feature, props, value })
      if (Array.isArray(result) && result.length > 0) {
        setItems(result)
      } else {
        setItems([])
      }
    } else if (!!items) {
      const matchingItems = items.filter((item) => {
        const propsAreMatching = props.some((prop) => item[prop].toLowerCase().includes(value.toLowerCase()))
        return propsAreMatching
      })
      setItems(matchingItems)
    } else {
      setItems([])
    }
  }

  const Item = ({ item }: { item: Item }) => (
    <div
      className={`border-t border-lightGray px-3 py-1 text-sm ${
        invert ? 'bg-blue text-white hover:bg-white hover:text-blue' : 'hover:bg-blue hover:text-white'
      } cursor-pointer text-sm`}
      key={item.value}
      onClick={overwrite(item)}
    >
      {item.value}
    </div>
  )

  const disable = () => {
    setTimeout(() => {
      setEnabled(false)
    }, 100)
  }

  return (
    <div className="relative">
      <Input onFocus={() => setEnabled(true)} onBlur={disable} value={value} onChange={updateInput} {...params} />
      {items.length > 0 && enabled && (
        <div
          className={`rounded-sm absolute flex flex-col w-full left-0 top-full mt-1 bg-white z-10 border ${invert ? 'border-white' : 'border-blue'}`}
        >
          <div className="max-h-40 overflow-x-hidden overflow-y-auto shadow-md">
            {items.map((item) => (
              <Item key={item.value} item={item} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default SuggestionInput
