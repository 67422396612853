import useProjects from '../../hooks/useContext/useProjects'
import Dropdown from '../Dropdown/Dropdown'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'

type Props = {
  value: string | string[]
  onChange: (value: string | string[]) => void
  required?: boolean
  multiple?: boolean
  disableLabel?: boolean
  useAllProjects?: boolean
}

const ProjectSelector: React.FC<Props> = ({ value, onChange, multiple = false, required = false, disableLabel = false, useAllProjects = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [projects] = useProjects()

  if (!projects) return null
  return (
    <Dropdown
      label={!disableLabel && t.UI.projectSelector.label}
      placeholder={!!disableLabel && t.UI.projectSelector.label}
      showEmptySelection
      emptySelection="-"
      required={required}
      items={projects.filter((p) => useAllProjects || !p.isArchived).map((c) => ({ label: c.name, value: c.uuid }))}
      value={value}
      onChange={onChange}
      multiple={multiple}
      className={!!disableLabel && '!w-fit shrink-0'}
    />
  )
}

export default ProjectSelector
