import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import ModalDynamicInput from '../../../UI/ModalDynamicInput/ModalDynamicInput'

type Props = {
  value: string[]
  onChange: (value: string[]) => void
}

const AreaManager: React.FC<Props> = ({ value, onChange }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return <ModalDynamicInput value={value} onChange={onChange} label={t.project.properties.areas.title} />
}

export default AreaManager
