import { useSelector } from 'react-redux'
import Button from '../Button/Button'
import props from '../../../redux/props'

type Props = { onSave: () => void; label?: string; small?: boolean }

const SaveBar: React.FC<Props> = ({ onSave, label, small = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  return (
    <div className="left-0 bottom-0 border-t bg-white border-lightGray fixed w-full">
      <div className={`py-2 flex justify-end w-full ${small ? 'max-w-6xl' : 'max-w-screen-2xl'} mx-auto px-3 sm:px-7`}>
        <Button onClick={onSave} text={label || t.generic.save} />
      </div>
    </div>
  )
}

export default SaveBar
