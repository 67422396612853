import Dropdown from '../Dropdown/Dropdown'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../../redux/props'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import { useEffect } from 'react'
import { setLocal } from '../../../redux/action/local'

type Props = {
  value: string | string[]
  onChange: (value: string | string[]) => void
  required?: boolean
  multiple?: boolean
  disableLabel?: boolean
}

const GewerkSelector: React.FC<Props> = ({ value, onChange, multiple = false, required = false, disableLabel = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const uniqueGewerke: string[] = useSelector((s) => s[props.UNIQUE_GEWERKE])
  const listGewerke = functional.use(Routes.LIST_UNIQUE_GEWERKE)
  const dispatch = useDispatch()

  useEffect(() => {
    const list = async () => {
      const gewerke = await listGewerke()
      if (Array.isArray(gewerke)) {
        dispatch(setLocal(props.UNIQUE_GEWERKE, gewerke))
      }
    }
    if (!Array.isArray(uniqueGewerke)) {
      list()
    }
  }, [uniqueGewerke, listGewerke, dispatch])

  if (!uniqueGewerke) return null
  return (
    <Dropdown
      label={!disableLabel && t.UI.gewerkSelector.label}
      placeholder={!!disableLabel && t.UI.gewerkSelector.label}
      showEmptySelection
      emptySelection="-"
      required={required}
      items={uniqueGewerke.map((c) => ({ label: c, value: c }))}
      value={value}
      onChange={onChange}
      multiple={multiple}
      className="!w-fit shrink-0"
    />
  )
}

export default GewerkSelector
