import { useSelector } from 'react-redux'
import { ThemaItem, ThemaItemStatus } from '../../../../../types/Protocol'
import props from '../../../../../redux/props'
import Textarea from '../../../../UI/Textarea/Textarea'
import Input from '../../../../UI/Input/Input'
import { TrashIcon } from '@heroicons/react/24/solid'
import RemoveConfirmation from '../../../../UI/RemoveConfirmation/RemoveConfirmation'
import Dropdown from '../../../../UI/Dropdown/Dropdown'
import Checkbox from '../../../../UI/Checkbox/Checkbox'
import Button from '../../../../UI/Button/Button'

type Props = {
  mainIndex: number
  items: ThemaItem[]
  onChange: (i: number, prop: keyof ThemaItem) => (value: any) => void
  addItem: () => void
  removeItem: (i: number) => () => void
}

const Items: React.FC<Props> = ({ mainIndex, items, onChange, addItem, removeItem }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="">
      <div className="flex gap-3">
        <div className="w-1/12 text-sm">{t.protocol.properties.themaProps.laufendeNummer}</div>
        <div className="w-4/12 text-sm">{t.protocol.properties.themaProps.text}</div>
        <div className="w-2/12 text-sm">{t.protocol.properties.themaProps.verantwortlich}</div>
        <div className="w-2/12 text-sm">{t.protocol.properties.themaProps.deadline}</div>
        <div className="w-2/12 text-sm">{t.protocol.properties.themaProps.status}</div>
        <div className="w-1/12 text-sm">{t.protocol.properties.themaProps.enableForExport}</div>
      </div>
      {Array.isArray(items) &&
        items.map((item, i) => (
          <div className="mt-2" key={i}>
            <div className="flex gap-3">
              <div className="w-1/12">
                <div>{`${mainIndex + 1}.${i + 1}`}</div>
                <div>
                  <RemoveConfirmation confirmationCallback={removeItem(i)}>
                    <TrashIcon className="fill-gray hover:fill-red w-5 cursor-pointer" />
                  </RemoveConfirmation>
                </div>
              </div>
              <div className="w-4/12">
                <Textarea value={item.text} onChange={onChange(i, 'text')} />
              </div>
              <div className="w-2/12">
                <Input type="text" value={item.verantwortlich} onChange={onChange(i, 'verantwortlich')} />
              </div>
              <div className="w-2/12">
                <Input type="date" value={item.deadline} onChange={onChange(i, 'deadline')} />
              </div>
              <div className="w-2/12">
                <Dropdown
                  value={item.status}
                  onChange={onChange(i, 'status')}
                  items={Object.values(ThemaItemStatus).map((status) => ({
                    label: t.protocol.properties.themaProps.statusItems[status],
                    value: status,
                  }))}
                />
              </div>
              <div className="w-1/12">
                <Checkbox checked={item.enableForExport} onChange={onChange(i, 'enableForExport')} />
              </div>
            </div>
          </div>
        ))}
      <div className="flex justify-end mt-2">
        <Button text={t.protocol.view.addThemaItemCTA} small onClick={addItem} />
      </div>
    </div>
  )
}

export default Items
