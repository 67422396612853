import { useSelector } from 'react-redux'
import { TimetrackerEntry } from '../../../../../types/Timetracker'
import props from '../../../../../redux/props'
import MinifiedEntry from './Entry/MinifiedEntry'
import TableEntry from './Entry/TableEntry'
import TableHeader from './Entry/TableHeader'
import { OnChange } from './View'

type Props = { entries?: TimetrackerEntry[]; onChange?: OnChange }

type EntriesByYearAndMonth = {
  [year: number]: {
    months: {
      [month: number]: {
        entries: TimetrackerEntry[]
        hours: number
        label: number
      }
    }
    label: number
    hours: number
  }
}

type FlatEntriesByYearAndMonth = {
  label: number
  months: {
    entries: TimetrackerEntry[]
    hours: number
    label: number
  }[]
  hours: number
}[]

const EntryList: React.FC<Props> = ({ entries, onChange }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const useTableView = !!onChange

  const sort = (a, b) => {
    return b.label - a.label
  }

  const sortByDate = (a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime()
  }

  const entriesByYearAndMonth = () => {
    const clusters = entries.sort(sortByDate).reduce((acc, e, i) => {
      const date = new Date(e.date)
      const year = date.getFullYear()
      const month = date.getMonth()
      if (!acc[year]) {
        acc[year] = {
          months: {},
          hours: 0,
          label: year,
        }
      }
      if (!acc[year].months[month]) {
        acc[year].months[month] = {
          entries: [],
          hours: 0,
          label: month,
        }
      }
      acc[year].months[month].entries.push({ ...e, number: i + 1 })
      acc[year].months[month].hours += e.hours || 0
      acc[year].hours += e.hours || 0
      return acc
    }, {} as EntriesByYearAndMonth)

    const flatClusters = Object.values(clusters)
      .sort(sort)
      .map((c) => {
        c.months = Object.values(c.months)
          .sort(sort)
          .map((m) => ({ ...m, entries: m.entries.reverse() }))
        return c
      }) as FlatEntriesByYearAndMonth

    return flatClusters
  }

  if (!entries) {
    return <div className="text-sm text-gray text-center">{t.fileSystem.toolbar.timetracker.entryList.pending}</div>
  } else if (!!entries && entries.length === 0) {
    return <div className="text-sm text-gray text-center">{t.fileSystem.toolbar.timetracker.entryList.empty}</div>
  } else if (!!entries) {
    if (useTableView) {
      return (
        <div className="flex h-fit flex-col">
          <div className="border-b border-lightGray">
            <TableHeader />
          </div>
          {entriesByYearAndMonth().map((yearCluster) => {
            return (
              <div key={yearCluster.label} className="flex flex-col">
                {yearCluster.months.map((m, i) => {
                  return (
                    <div key={m.label}>
                      {m.entries.map((e, i) => {
                        return <TableEntry key={e.uuid} entry={e} onChange={onChange(e.uuid)} />
                      })}
                      <div className={i === yearCluster.months.length - 1 ? 'border-t !border-blue mt-1 pt-2' : 'border-y !border-blue my-1 py-2'}>
                        <div className="text-sm text-blue font-bold text-right">
                          {t.timetracking.properties.subSumMonthLabel(t.generic.months[m.label], yearCluster.label, m.hours)}
                        </div>
                      </div>
                    </div>
                  )
                })}
                <div className="border-b !border-blue mb-1 pb-2">
                  <div className="text-sm text-blue font-bold text-right">
                    {t.timetracking.properties.subSumYearLabel(yearCluster.label, yearCluster.hours)}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )
    } else {
      return (
        <div className="flex h-fit flex-col divide-y divide-lightGray">
          {entries.map((e) => {
            return <MinifiedEntry key={e.uuid} entry={e} />
          })}
        </div>
      )
    }
  } else {
    return null
  }
}

export default EntryList
