import React from 'react'
import { CheckboxContainer, HiddenCheckbox, StyledCheckbox, StyledSpan, Wrapper } from './Checkbox.Styled'
import { CheckIcon } from '@heroicons/react/24/outline'

type Props = {
  className?: string
  checked?: boolean
  label?: string
  margin?: string
  width?: string
  onChange: (value: boolean) => void
  color?: string
  round?: boolean
  required?: boolean
}

const Checkbox: React.FC<Props> = ({ className, checked = false, label, margin, width, onChange, color, round, required, ...props }) => (
  <Wrapper width={width} margin={margin}>
    <CheckboxContainer className={className}>
      <HiddenCheckbox required={required} checked={checked} onChange={() => {}} {...props} />

      <StyledCheckbox color={color} round={round} checked={checked} onClick={() => onChange(!checked)}>
        {!!checked && <CheckIcon className="h-4 stroke-blue stroke-2 -ml-[1px]" />}
      </StyledCheckbox>
      {!!label && (
        <StyledSpan color={color} onClick={() => onChange(!checked)}>
          {label}
        </StyledSpan>
      )}
    </CheckboxContainer>
  </Wrapper>
)

export default Checkbox
