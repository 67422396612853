import { useSelector } from 'react-redux'
import Checkbox from '../../../UI/Checkbox/Checkbox'
import DateRangePicker from '../../../UI/DateRangePicker/DateRangePicker'
import MemberSelector from '../../../UI/MemberSelector/MemberSelector'
import ProjectSelector from '../../../UI/ProjectSelector/ProjectSelector'
import props from '../../../../redux/props'
import { TimetrackerFilter } from '../../../../types/Timetracker'

type Props = {
  disableProjectSelection?: boolean
  filterConfig: TimetrackerFilter
  updateFilterConfig: (key: keyof TimetrackerFilter) => (value: any) => void
}

const FilterCore: React.FC<Props> = ({ disableProjectSelection = false, filterConfig, updateFilterConfig }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="flex flex-col gap-2">
      {!disableProjectSelection && <ProjectSelector value={filterConfig.projectUUID} onChange={updateFilterConfig('projectUUID')} />}
      <MemberSelector value={filterConfig.memberUUID} onChange={updateFilterConfig('memberUUID')} />
      <DateRangePicker value={filterConfig.dateRange} onChange={updateFilterConfig('dateRange')} />
      <Checkbox
        label={t.timetracking.filter.includeIsMeeting}
        checked={filterConfig.includeIsMeeting}
        onChange={updateFilterConfig('includeIsMeeting')}
      />
      <Checkbox
        label={t.timetracking.filter.includeNotBillable}
        checked={filterConfig.includeNotBillable}
        onChange={updateFilterConfig('includeNotBillable')}
      />
    </div>
  )
}

export default FilterCore
