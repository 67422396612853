import { Bewertung } from '../../../../../types/Contact'

const Rating: React.FC<{ rating: Bewertung }> = ({ rating }) => {
  if (rating === Bewertung.GOOD) {
    return <div className="bg-green w-4 h-4 rounded-full" />
  } else if (rating === Bewertung.MEDIUM) {
    return <div className="bg-yellowDefault w-4 h-4 rounded-full" />
  } else if (rating === Bewertung.BAD) {
    return <div className="bg-red w-4 h-4 rounded-full" />
  } else {
    return null
  }
}

export default Rating
