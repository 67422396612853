import { useSelector } from 'react-redux'
import { formatNumber, getPrettyDate, getWeekOfYear } from '../../../../../../utility'
import props from '../../../../../../redux/props'

const MinifiedEntry = ({ entry }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  return (
    <div className="py-1">
      <div className="flex items-center">
        <div className="w-1/3 truncate font-bold">{`${entry.member.firstname} ${entry.member.lastname}`}</div>
        <div className="w-1/3 truncate text-sm">
          {t.generic.calendarWeekPrefix}
          {getWeekOfYear(new Date(entry.date))}
        </div>
        <div className="w-1/3 truncate text-sm">
          {t.generic.datePrefix}
          {getPrettyDate(entry.date)}
        </div>
      </div>
      <div className="flex items-center">
        <div className="w-1/3 truncate text-sm">{`${t.generic.hourPrefix}${formatNumber(entry.hours)}`}</div>
        {entry.notBillable && <div className={`font-bold text-gray text-sm w-1/3 truncate`}>{t.fileSystem.toolbar.timetracker.notBillable}</div>}
        {entry.isMeeting && <div className={`font-bold text-gray text-sm w-1/3 truncate`}>{t.fileSystem.toolbar.timetracker.isMeeting}</div>}
      </div>
      <div className="flex items-center">
        {!!entry.area && (
          <div className="text-sm flex-1 truncate">
            {t.fileSystem.toolbar.timetracker.entryList.areaPrefix}
            {entry.area}
          </div>
        )}
      </div>
      <div className="text-sm whitespace-pre-wrap">{entry.description}</div>
    </div>
  )
}

export default MinifiedEntry
