import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import useMember from '../../hooks/useContext/useMember'
import ModalChecklist from '../ModalChecklist/ModalChecklist'
import Dropdown from '../Dropdown/Dropdown'

type Props = {
  label?: string
  value: string | string[]
  onChange: (value: string | string[]) => void
  multiple?: boolean
  required?: boolean
  disableLabel?: boolean
}

const MemberSelector: React.FC<Props> = ({ label, value, onChange, multiple = false, required = false, disableLabel = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [members] = useMember()

  const resolveMemberName = (uuid: string) => {
    if (!members) return null
    const member = members.find((c) => c.uuid === uuid)
    return member ? `${member.firstname} ${member.lastname}` : null
  }

  if (!members) return null
  if (multiple) {
    return (
      <ModalChecklist
        label={label || t.UI.memberSelector.label}
        items={members.map((m) => m.uuid)}
        value={value as string[]}
        onChange={onChange}
        resolveName={resolveMemberName}
      />
    )
  } else {
    return (
      <Dropdown
        label={!disableLabel && t.UI.memberSelector.label}
        placeholder={!!disableLabel && t.UI.memberSelector.label}
        showEmptySelection
        emptySelection="-"
        required={required}
        items={members.map((m) => ({ label: resolveMemberName(m.uuid), value: m.uuid }))}
        value={value}
        onChange={onChange}
        multiple={multiple}
      />
    )
  }
}

export default MemberSelector
