type Props = Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> & {
  onChange?: (value: string) => void
  value: string
  label?: string | boolean
  placeholder?: string
  disabled?: boolean
  className?: string
  rows?: number
  required?: boolean
  id?: string
  minCharacters?: number
  maxCharacters?: number
  useRef?: any
  invert?: boolean
  contentClassName?: string
  highlightLabel?: boolean
}

const Textarea: React.FC<Props> = ({
  onChange,
  value,
  label,
  placeholder,
  disabled,
  className,
  rows = 3,
  required = false,
  id,
  minCharacters,
  maxCharacters,
  useRef,
  invert = false,
  contentClassName = '',
  highlightLabel = false,
}) => {
  return (
    <div className={`w-full ${className}`}>
      {label && (
        <label htmlFor={id} className={highlightLabel ? 'text-blue font-bold' : invert ? 'text-white text-sm' : 'text-gray text-sm'}>
          {typeof label === 'string' ? label : placeholder}
        </label>
      )}
      <textarea
        id={id}
        ref={useRef}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        placeholder={placeholder}
        required={required}
        className={`${
          invert
            ? 'rounded-sm border border-white placeholder-lightGray text-white w-full py-1 px-1 bg-blue focus:outline-none focus:shadow-outline'
            : 'rounded-sm border border-blue placeholder-gray w-full py-1 px-1 bg-white text-blue focus:outline-none focus:shadow-outline'
        } ${className} ${contentClassName}`}
        rows={rows}
        minLength={minCharacters}
        maxLength={maxCharacters}
      />
    </div>
  )
}

export default Textarea
