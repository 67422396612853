import { useEffect } from 'react'
import PublicFileUpload from '../../../../UI/PublicFileUpload/PublicFileUpload'
import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { PublicFile } from '../../../../../types/Generic'
import { formatFileSize } from '../../../../../utility'
import GetFileIcon from '../../../../UI/GetFileIcon/GetFileIcon'
import usePublicFileDownload from '../../../../UI/PublicFileUpload/usePublicFileDownload'
import RemoveConfirmation from '../../../../UI/RemoveConfirmation/RemoveConfirmation'
import { TrashIcon } from '@heroicons/react/24/solid'

type Props = {
  label: string
  protocolUUID: string
  files: PublicFile[]
  onChange: (value: PublicFile[]) => void
}

const Attachments: React.FC<Props> = ({ label, protocolUUID, files, onChange }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const startDownload = usePublicFileDownload()

  useEffect(() => {
    if (!Array.isArray(files)) {
      onChange([])
    }
  }, [files, onChange])

  const addFiles = (newFiles: PublicFile[]) => {
    onChange([...files, ...newFiles])
  }

  const download = (file: PublicFile) => () => startDownload(file)

  const remove = (file: PublicFile) => () => {
    onChange(files.filter((f) => f.key !== file.key))
  }

  if (!Array.isArray(files)) return null
  return (
    <div>
      <div className="flex justify-between">
        <div className="font-bold text-lg">{label}</div>
        <PublicFileUpload folder={`protocol/${protocolUUID}`} addFiles={addFiles} />
      </div>
      <div className="flex flex-col py-3">
        {files.length === 0 && <div className="text-gray text-center text-sm">{t.protocol.properties.attachmentProps.empty}</div>}
        {files.map((file) => (
          <div key={file.key} className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-2 hover:underline cursor-pointer" onClick={download(file)}>
              <GetFileIcon filename={file.name} />
              <div>{file.name}</div>
            </div>
            <div className="flex items-center gap-2">
              <div>{formatFileSize(file.size)}</div>
              <RemoveConfirmation itemName={file.name} confirmationCallback={remove(file)}>
                <TrashIcon className="fill-gray hover:fill-red w-5 cursor-pointer" />
              </RemoveConfirmation>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Attachments
