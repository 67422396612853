import { functional } from '@think-internet/zeus-frontend-package'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Routes from '../../../redux/routes'
import useToast from '../../hooks/useToast'
import { PublicFile } from '../../../types/Generic'

type Response = (file: PublicFile) => Promise<void>

const usePublicFileDownload = (): Response => {
  const toast = useToast()
  const t = useSelector((s) => s[props.TRANSLATION])
  const downloadFile = functional.use(Routes.GET_PUBLIC_PRESIGNED_DOWNLOAD_URL)

  const start: Response = async (file: PublicFile) => {
    const presignedURL = await downloadFile({ file })
    if (presignedURL) {
      const url = presignedURL
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${file.name}`)
      document.body.appendChild(link)
      link.click()
      link.remove()
    } else {
      toast(t.UI.publicFileUpload.download.error)
    }
  }

  return start
}

export default usePublicFileDownload
