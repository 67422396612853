import DocViewer from 'react-doc-viewer'
import useDownloadFile from '../../ContextMenu/hooks/useDownloadFile'
import { useEffect, useState } from 'react'

const Preview = ({ item }) => {
  const downloadFile = useDownloadFile(true)
  const [url, setUrl] = useState(null)

  useEffect(() => {
    const startDownload = async () => {
      const url = await downloadFile(item)()
      setUrl(url)
    }
    if (!url) {
      startDownload()
    }
  }, [downloadFile, item, url])

  if (!url) {
    return <div>Loading...</div>
  }
  const docs = [{ uri: url }]

  return <DocViewer documents={docs} />
}

export default Preview
