import { useSelector } from 'react-redux'
import Modal from '../../../UI/Modal/Modal'
import props from '../../../../redux/props'
import { useState } from 'react'
import Button from '../../../UI/Button/Button'
import FilterCore from './FilterCore'
import { TimetrackerFilter } from '../../../../types/Timetracker'

const FilterConfigModal = ({ filterConfig, setFilterConfig, disableProjectSelection = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [isOpen, setIsOpen] = useState(false)
  const [internalFilterConfig, setInternalFilterConfig] = useState({ ...filterConfig })

  const updateFilterConfig = (key: keyof TimetrackerFilter) => (value: any) => {
    setInternalFilterConfig((prev) => ({ ...prev, [key]: value }))
  }

  const confirm = () => {
    setFilterConfig(internalFilterConfig)
    toggle()
  }

  const toggle = () => setIsOpen(!isOpen)

  return (
    <div>
      <Button text={t.timetracking.filter.cta} onClick={toggle} />
      <Modal title={t.timetracking.filter.modalTitle} show={isOpen} onClose={toggle} onConfirm={confirm}>
        <FilterCore disableProjectSelection={disableProjectSelection} filterConfig={internalFilterConfig} updateFilterConfig={updateFilterConfig} />
      </Modal>
    </div>
  )
}

export default FilterConfigModal
