type Props = {
  value: boolean
  onChange: (value: boolean) => void
  disabled?: boolean
  label?: string
  required?: boolean
  hint?: string
}

const Toggle: React.FC<Props> = ({ value, onChange, label, hint, disabled = false, required = false }) => {
  return (
    <div className="flex flex-col">
      <label className="inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          required={required}
          checked={value}
          disabled={disabled}
          onChange={(e) => onChange(e.target.checked)}
          className="sr-only peer"
        />
        <div className="shrink-0 relative w-11 h-6 bg-gray peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray peer-checked:bg-blue"></div>
        {!!label && <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{label}</span>}
      </label>
      {!!hint && <div className="text-xs mt-1 text-gray w-1/3 min-w-[500px]">{hint}</div>}
    </div>
  )
}

export default Toggle
