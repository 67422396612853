import { Project } from './Project'

export enum Bewertung {
  GOOD = 'GOOD',
  MEDIUM = 'MEDIUM',
  BAD = 'BAD',
}

export type Ansprechpartner = {
  uuid: string
  vorname?: string
  nachname?: string
  position?: string
  telefon?: string
  mobil?: string
  mail?: string
}

export type Contact = {
  uuid?: string
  gewerk?: string
  firma?: string
  ansprechpartnerList?: Ansprechpartner[]
  adresse?: string
  telefon?: string
  mobil?: string
  mail?: string
  website?: string
  kuerzel?: string
  bemerkung?: string
  bewertung?: Bewertung
  isSensitive?: boolean

  // dynamic properties
  projects?: Array<Project>
  projectUUIDList?: string[]
}
