import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { Thema, ThemaItem, ThemaItemStatus } from '../../../../../types/Protocol'
import { useEffect } from 'react'
import EditInput from '../EditInput'
import Add from './Add'
import Items from './Items'

type Props = {
  label: string
  value: Thema[]
  onChange: (value: Thema[]) => void
}

const Themen: React.FC<Props> = ({ label, value, onChange }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  useEffect(() => {
    if (!Array.isArray(value)) {
      onChange([])
    }
  }, [value, onChange])

  const updateThemaTitle = (index: number, key: keyof Thema) => (propValue: any) => {
    const newValue = [...value]
    newValue[index] = { ...newValue[index], [key]: propValue }
    onChange(newValue)
  }

  const add = (themaTitle: string) => {
    onChange([
      ...value,
      {
        thema: themaTitle,
        items: [
          {
            text: '',
            verantwortlich: '',
            deadline: '',
            status: ThemaItemStatus.NEW,
            enableForExport: true,
          },
        ],
      },
    ])
  }

  const remove = (index: number) => () => {
    const newValue = [...value]
    newValue.splice(index, 1)
    onChange(newValue)
  }

  const updateItem = (index: number) => (i: number, propKey: keyof ThemaItem) => (propValue: any) => {
    const newValue = [...value]
    const items = newValue[index].items
    items[i] = { ...items[i], [propKey]: propValue }
    newValue[index] = { ...newValue[index], items }
    onChange(newValue)
  }

  const addItem = (index: number) => () => {
    const newValue = [...value]
    newValue[index].items.push({
      text: '',
      verantwortlich: '',
      deadline: '',
      status: ThemaItemStatus.NEW,
      enableForExport: true,
    })
    onChange(newValue)
  }

  const removeItem = (index: number) => (i: number) => () => {
    const newValue = [...value]
    const items = newValue[index].items
    items.splice(i, 1)
    newValue[index] = { ...newValue[index], items }
    onChange(newValue)
  }

  return (
    <div>
      <div className="flex items-center justify-between pb-5">
        <div className="font-bold text-lg">{label}</div>
        <Add callback={add} />
      </div>
      <div className="flex flex-col">
        {Array.isArray(value) &&
          value.map((v, i) => (
            <div key={i} className="py-5 border-t border-graySecondary">
              <div className="flex gap-2" key={i}>
                <div className="font-bold text-md">{`${i + 1}.`} </div>
                <EditInput
                  boldValue
                  noLabel
                  label={t.protocol.properties.themaProps.title}
                  initValue={v.thema}
                  onChange={updateThemaTitle(i, 'thema')}
                  onRemove={remove(i)}
                />
              </div>
              <Items mainIndex={i} items={v.items} onChange={updateItem(i)} addItem={addItem(i)} removeItem={removeItem(i)} />
            </div>
          ))}
      </div>
    </div>
  )
}

export default Themen
