import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import Input from '../../../../UI/Input/Input'
import { Teilnehmer as TeilnehmerType } from '../../../../../types/Protocol'
import { useEffect } from 'react'
import ContactAnsprechpartnerSelector, {
  AnsprechartnerWithContact,
} from '../../../../UI/ContactAnsprechpartnerSelector/ContactAnsprechpartnerSelector'
import { TrashIcon } from '@heroicons/react/24/solid'

type Props = {
  label: string
  value: TeilnehmerType[]
  onChange: (value: TeilnehmerType[]) => void
}

const Teilnehmer: React.FC<Props> = ({ label, value, onChange }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  useEffect(() => {
    if (!Array.isArray(value)) {
      onChange([])
    }
  }, [value, onChange])

  const updateProp = (index: number, key: keyof TeilnehmerType) => (propValue: any) => {
    const newValue = [...value]
    newValue[index] = { ...newValue[index], [key]: propValue }
    onChange(newValue)
  }

  const getAnsprechpartnerFromTeilnehmer = (): AnsprechartnerWithContact[] => {
    return value.map((t) => ({
      ...t,
      uuid: t.ansprechpartnerUUID,
      contactUUID: t.contactUUID,
    }))
  }

  const internalOnChange = (ansprechpartner: AnsprechartnerWithContact[]) => {
    const teilnehmer: TeilnehmerType[] = ansprechpartner.map((a) => ({
      contactUUID: a.contactUUID,
      ansprechpartnerUUID: a.uuid,
      vorname: a.vorname,
      nachname: a.nachname,
      mail: a.mail,
      kuerzel: a.contact?.kuerzel || '',
      firma: a.contact?.firma || '',
    }))
    onChange(teilnehmer)
  }

  const remove = (index: number) => () => {
    onChange(value.filter((_, i) => i !== index))
  }

  if (!value) return null
  return (
    <div>
      <div className="font-bold">{label}</div>
      <div>
        <div className="flex gap-3">
          <div className="w-1/5 text-sm">{t.protocol.properties.teilnehmerProps.vorname}</div>
          <div className="w-1/5 text-sm">{t.protocol.properties.teilnehmerProps.nachname}</div>
          <div className="w-1/5 text-sm">{t.protocol.properties.teilnehmerProps.kuerzel}</div>
          <div className="w-1/5 text-sm">{t.protocol.properties.teilnehmerProps.firma}</div>
          <div className="w-1/5 text-sm">{t.protocol.properties.teilnehmerProps.mail}</div>
          <div className="w-5"></div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {Array.isArray(value) &&
          value.map((v, i) => (
            <div className="flex gap-2 items-center" key={i}>
              <div className="w-1/5">
                <Input value={v.vorname} onChange={updateProp(i, 'vorname')} />
              </div>
              <div className="w-1/5">
                <Input value={v.nachname} onChange={updateProp(i, 'nachname')} />
              </div>
              <div className="w-1/5">
                <Input value={v.kuerzel} onChange={updateProp(i, 'kuerzel')} />
              </div>
              <div className="w-1/5">
                <Input value={v.firma} onChange={updateProp(i, 'firma')} />
              </div>
              <div className="w-1/5">
                <Input value={v.mail} onChange={updateProp(i, 'mail')} />
              </div>
              <div>
                <TrashIcon className="w-5 fill-gray hover:fill-red cursor-pointer" onClick={remove(i)} />
              </div>
            </div>
          ))}
      </div>
      <div className="flex justify-end mt-2">
        <ContactAnsprechpartnerSelector noPreview noLabel value={getAnsprechpartnerFromTeilnehmer()} onChange={internalOnChange} />
      </div>
    </div>
  )
}

export default Teilnehmer
