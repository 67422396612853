import { crud } from '@think-internet/zeus-frontend-package'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Remove } from '../../../hooks/useContext/types'
import { ProtocolEntry } from '../../../../types/Protocol'
import props from '../../../../redux/props'
import useToast, { Type } from '../../../hooks/useToast'

type Set = (key: keyof ProtocolEntry) => (value: any) => void
type Update = (item: ProtocolEntry, silent?: Boolean) => void

type Response = [data: ProtocolEntry, set: Set, update: Update, remove: Remove]

const useProtocol = (uuid: string): Response => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const protocolEntryFeature = crud.use(props.PROTOCOL_ENTRY)
  const [data, setData] = useState<ProtocolEntry>()
  const toast = useToast()

  useEffect(() => {
    const get = async () => {
      const entry = await protocolEntryFeature.get({ uuid })
      if (entry) {
        setData(entry)
      }
    }
    if (!data) {
      get()
    }
  }, [data, protocolEntryFeature, uuid])

  // save every 10 seconds
  useEffect(() => {
    const save = async () => {
      await protocolEntryFeature.upsert(data)
    }
    const interval = setInterval(() => {
      if (!!data) {
        save()
      }
    }, 10000)
    return () => clearInterval(interval)
  }, [data, protocolEntryFeature])

  const update: Update = async (item: ProtocolEntry, silent = false) => {
    const updated = await protocolEntryFeature.upsert(item)
    if (!!updated) {
      setData(updated)
      if (!silent) {
        toast(t.protocol.success.update, Type.SUCCESS)
      }
    } else {
      toast(t.protocol.error.update)
    }
  }

  const remove: Remove = async (uuid: string) => {
    const removed = await protocolEntryFeature.remove({ uuid })
    if (!!removed) {
      toast(t.protocol.success.remove, Type.SUCCESS)
    } else {
      toast(t.protocol.error.remove)
    }
  }

  const set: Set = (key: keyof ProtocolEntry) => (value: any) => {
    setData({ ...data, [key]: value })
  }

  return [data, set, update, remove]
}

export default useProtocol
