import { useState } from 'react'
import Export from '../../../../UI/Export/Export'
import Routes from '../../../../../redux/routes'
import FilterCore from '../../FilterConfigModal/FilterCore'
import { Project } from '../../../../../types/Project'
import { TimetrackerFilter } from '../../../../../types/Timetracker'

type Props = {
  project: Project
}

const FilterAndExport: React.FC<Props> = ({ project }) => {
  const [filterConfig, setFilterConfig] = useState<TimetrackerFilter>({ projectUUID: project.uuid, includeIsMeeting: true, includeNotBillable: true })

  const updateFilterConfig = (prop: keyof TimetrackerFilter) => (value: any) => {
    setFilterConfig((prev) => ({ ...prev, [prop]: value }))
  }

  return (
    <Export
      small
      filter={filterConfig}
      context={{
        fileName: 'Zeiterfassung',
        headline: 'Zeiterfassung',
      }}
      route={Routes.GENERATE_TIMETRACKING_EXPORT}
      translationProp="timetracking"
      availableProperties={[
        'project.name',
        'member.firstname',
        'member.lastname',
        'area',
        'description',
        'hours',
        'date',
        'calendarWeek',
        'notBillable',
        'isMeeting',
      ]}
      preSelectedProperties={['project.name', 'area', 'description', 'hours', 'date', 'calendarWeek']}
    >
      <FilterCore disableProjectSelection filterConfig={filterConfig} updateFilterConfig={updateFilterConfig} />
    </Export>
  )
}

export default FilterAndExport
