import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Modal from '../Modal/Modal'
import { useEffect, useState } from 'react'
import Checklist from '../Checklist/Checklist'
import Button from '../Button/Button'
import Input from '../Input/Input'

export type ChecklistCategory = {
  name: string
  uuid: string
  items: {
    label: string
    uuid: string
  }[]
}

export type ValueItem = {
  categoryUUID: string
  itemUUID: string
}

type Props = {
  label: string
  items: ChecklistCategory[]
  value: ValueItem[]
  onChange: (value: ValueItem[]) => void
  resolveName: (uuid: ValueItem) => string
}

const ModalCategoryChecklist: React.FC<Props> = ({ label, items, value, onChange, resolveName }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [isOpen, setIsOpen] = useState(false)
  const [searchInput, setSearchInput] = useState('')

  const toggle = () => setIsOpen(!isOpen)

  const filter = (category: ChecklistCategory) => {
    if (searchInput.length > 0) {
      return category.items.some((item) => item.label.toLowerCase().includes(searchInput.toLowerCase()))
    }
    return true
  }

  const onChangeInternal = (categoryUUID: string) => (selectedItems: string[]) => {
    onChange(value.filter((v) => v.categoryUUID !== categoryUUID).concat(selectedItems.map((itemUUID) => ({ categoryUUID, itemUUID }))))
  }

  const getCategoryValue = (categoryUUID: string): string[] => {
    return value.filter((v) => v.categoryUUID === categoryUUID).map((v) => v.itemUUID)
  }

  useEffect(() => {
    if (!Array.isArray(value)) {
      onChange([])
    }
  }, [value, onChange])

  if (!Array.isArray(items) || !Array.isArray(value)) return null
  return (
    <div>
      {!!label && <label className="text-gray text-sm">{label}</label>}
      <div className="flex flex-col">
        {!!resolveName && Array.isArray(value) && <div>{value.map((v) => resolveName(v)).join(', ')}</div>}
        <Button onClick={toggle} className="mt-1 text-left" text={t.generic.edit} small />
      </div>
      <Modal title={label} noForm onClose={toggle} show={isOpen} onConfirm={toggle}>
        <div className="flex flex-col gap-3">
          <Input placeholder={t.generic.search} value={searchInput} onChange={setSearchInput} />
          {items.filter(filter).map((category, i) => (
            <div key={i}>
              <div className="font-bold text-gray">{category.name}</div>
              <Checklist
                className="max-h-[500px] overflow-x-hidden overflow-y-auto"
                value={getCategoryValue(category.uuid)}
                items={category.items}
                onChange={onChangeInternal(category.uuid)}
              />
            </div>
          ))}
        </div>
      </Modal>
    </div>
  )
}

export default ModalCategoryChecklist
