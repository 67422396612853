import Modal from '../../../../UI/Modal/Modal'
import { useState } from 'react'
import Input from '../../../../UI/Input/Input'
import props from '../../../../../redux/props'
import { useSelector } from 'react-redux'
import Button from '../../../../UI/Button/Button'

const Add = ({ callback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState('')

  const toggle = () => setOpen(!open)

  const save = () => {
    setValue('')
    callback(value)
    toggle()
  }

  return (
    <>
      <div className="flex justify-end">
        <Button text={t.protocol.view.addThemaCTA} onClick={toggle} small />
      </div>
      <Modal onClose={toggle} show={open} onConfirm={save}>
        <div className="text-blue font-bold">{t.protocol.properties.themaProps.title}</div>
        <Input value={value} onChange={setValue} />
      </Modal>
    </>
  )
}

export default Add
