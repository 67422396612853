import { getWeekOfYear } from '../../../../../../utility'
import { TimetrackerEntry } from '../../../../../../types/Timetracker'
import Number from '../../../../../UI/Input/Number'
import ProjectAreaSelector from '../../../../../UI/ProjectAreaSelector/ProjectAreaSelector'
import Textarea from '../../../../../UI/Textarea/Textarea'
import DaySelector from '../../../../../UI/DaySelector/DaySelector'
import Checkbox from '../../../../../UI/Checkbox/Checkbox'

type Props = {
  entry: TimetrackerEntry
  onChange: (key: keyof TimetrackerEntry) => (value: any) => void
}

const inputClass = 'border-none !bg-lightGray !text-blue'

const TableEntry: React.FC<Props> = ({ entry, onChange }) => {
  return (
    <div className="py-1">
      <div className="flex items-center">
        {/* <div className="w-[60px] break-words text-sm shrink-0 grow-0 font-bold px-1">{t.timetracking.properties.laufendeNummer}</div> */}
        <div className="w-[60px] break-words text-sm shrink-0 grow-0">{`${entry.number}.`}</div>
        <div className="w-1/12 break-words text-sm shrink-0 grow-0 px-1">{getWeekOfYear(new Date(entry.date))}</div>
        <div className="w-1/12 break-words text-sm shrink-0 grow-0 px-1">
          <Number contentClassName={inputClass} decimal value={entry.hours} onChange={onChange('hours')} />
        </div>
        <div className="w-1/12 break-words text-sm shrink-0 grow-0 px-1">
          <DaySelector contentClassName={inputClass} value={entry.date} onChange={onChange('date')} />
        </div>
        <div className="w-2/12 break-words text-sm shrink-0 grow-0 px-1">{`${entry.member.firstname} ${entry.member.lastname}`}</div>
        <div className="w-2/12 break-words text-sm shrink-0 grow-0 px-1">
          <ProjectAreaSelector
            contentClassName={inputClass}
            disableLabel
            projectUUID={entry.projectUUID}
            value={entry.area}
            onChange={onChange('area')}
          />
        </div>
        <div className="w-1/12 break-words text-sm shrink-0 grow-0 px-1 flex justify-center">
          <Checkbox width="w-fit" checked={!entry.notBillable} onChange={(v) => onChange('notBillable')(!v)} />
        </div>
        <div className="w-1/12 break-words text-sm shrink-0 grow-0 px-1 flex justify-center">
          <Checkbox width="w-fit" checked={entry.isMeeting} onChange={onChange('isMeeting')} />
        </div>
        <div className="w-3/12 break-words text-sm  px-1">
          <Textarea contentClassName="!w-full !bg-lightGray text-blue border-none" value={entry.description} onChange={onChange('description')} />
        </div>
      </div>
    </div>
  )
}

export default TableEntry
