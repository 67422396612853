import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import FullHeightWrapper from '../../../UI/FullHeightWrapper/FullHeightWrapper'
import Entry from './Entry'
import SimpleList from '../../../UI/SimpleList/SimpleList'
import useProjects from '../../../hooks/useContext/useProjects'
import Container from '../../../UI/Container/Container'
import { Project } from '../../../../types/Project'
import Create from './Create'

const Projects = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [projects] = useProjects()

  const filter = (search: string) => (project: Project) => {
    if (search.length > 0) {
      const name = !!project.name ? project.name.toLowerCase().includes(search.toLowerCase()) : false
      return name
    } else {
      return true
    }
  }

  return (
    <FullHeightWrapper>
      <Container>
        <div className="py-10">
          <SimpleList
            listClassName="flex flex-col gap-1"
            itemClassName="border-none"
            title={t.project.title}
            data={projects}
            filter={filter}
            Component={Entry}
            Upsert={Create}
          />
        </div>
      </Container>
    </FullHeightWrapper>
  )
}

export default Projects
