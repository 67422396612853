import { useSelector } from 'react-redux'
import { useState } from 'react'
import { crud } from '@think-internet/zeus-frontend-package'
import props from '../../../../../redux/props'
import useToast, { Type } from '../../../../hooks/useToast'
import { ProtocolEntry, ThemaItemStatus } from '../../../../../types/Protocol'
import HasPermission from '../../../HasPermission/HasPermission'
import Button from '../../../../UI/Button/Button'
import Modal from '../../../../UI/Modal/Modal'
import ProjectSelector from '../../../../UI/ProjectSelector/ProjectSelector'

const Create: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const toast = useToast()
  const [data, setData] = useState<ProtocolEntry>({
    date: new Date(),
    themen: [
      {
        thema: 'Organisatorisches',
        items: [
          {
            enableForExport: true,
            status: ThemaItemStatus.NEW,
          },
        ],
      },
      {
        thema: 'Planung',
        items: [
          {
            enableForExport: true,
            status: ThemaItemStatus.NEW,
          },
        ],
      },
      {
        thema: 'Ausschreibung und Vergabe',
        items: [
          {
            enableForExport: true,
            status: ThemaItemStatus.NEW,
          },
        ],
      },
      {
        thema: 'Ausführung',
        items: [
          {
            enableForExport: true,
            status: ThemaItemStatus.NEW,
          },
        ],
      },
      {
        thema: 'Sonstiges',
        items: [
          {
            enableForExport: true,
            status: ThemaItemStatus.NEW,
          },
        ],
      },
    ],
  })
  const [isOpen, setIsOpen] = useState(false)
  const protocolEntryFeature = crud.use(props.PROTOCOL_ENTRY)

  const save = async () => {
    const result: ProtocolEntry = await protocolEntryFeature.upsert(data)
    if (!!result) {
      window.open(`/dashboard/protocol/entry/${result.uuid}`, '_self')
    } else {
      toast(t.protocol.create.error, Type.ERROR)
    }
  }

  const toggle = () => setIsOpen(!isOpen)

  const set = (key: keyof ProtocolEntry) => (value: any) => setData({ ...data, [key]: value })

  if (!data) return null
  return (
    <HasPermission permission="createProtocol">
      <Button onClick={toggle} text={t.protocol.create.cta} />
      <Modal onClose={toggle} show={isOpen} onConfirm={save} title={t.protocol.create.title}>
        <div className="flex gap-5">
          <ProjectSelector value={data.projectUUID} onChange={set('projectUUID')} />
        </div>
      </Modal>
    </HasPermission>
  )
}

export default Create
