import { functional } from '@think-internet/zeus-frontend-package'
import { useState } from 'react'
import { s3Upload } from '@think-internet/zeus-frontend-package/fetch/fetch'
import Routes from '../../../redux/routes'
import { PublicFile } from '../../../types/Generic'

type Response = {
  key: string
  presignedURL: string
}

export enum FileUploadStatus {
  READY,
  UPLOADING,
}

export type FileUploadState = {
  total?: number
  remaining?: number
  progress?: number
  workingOn?: string
  status: FileUploadStatus
}

type UsePublicFileUpload = {
  fileUpload: (prefix: string, files: File[]) => Promise<PublicFile[]>
  state: FileUploadState
}

const usePublicFileUpload = (): UsePublicFileUpload => {
  const [state, setState] = useState<FileUploadState>({ status: FileUploadStatus.READY })
  const getPublicUploadPresignedURL = functional.use(Routes.GET_PUBLIC_PRESIGNED_UPLOAD_URL)

  const updateProgress = (progress: number) => {
    setState((state) => ({
      ...state,
      progress,
    }))
  }

  const reduceRemaining = () => {
    setState((state) => ({
      ...state,
      remaining: state.remaining - 1,
    }))
  }

  const workingOn = (name: string) => {
    setState((state) => ({
      ...state,
      workingOn: name,
    }))
  }

  const resetState = () => {
    setState((state) => ({
      ...state,
      progress: 0,
      status: FileUploadStatus.READY,
    }))
  }

  const fileUpload = async (folder: string, files: File[]): Promise<PublicFile[]> => {
    setState({
      total: files.length,
      remaining: files.length,
      progress: 0,
      status: FileUploadStatus.UPLOADING,
    })
    const uploadedFiles: PublicFile[] = []
    for (const file of files) {
      workingOn(file.name)
      const response: Response = await getPublicUploadPresignedURL({
        folder,
        meta: {
          name: file.name,
          type: file.type,
          size: file.size,
        },
      })
      if (!response) continue
      const { key, presignedURL } = response

      const uploadResult = await s3Upload(presignedURL, file, updateProgress)
      if (!uploadResult) continue
      reduceRemaining()
      uploadedFiles.push({
        name: file.name,
        type: file.type,
        size: file.size,
        key,
      })
    }
    resetState()
    return uploadedFiles
  }

  return {
    fileUpload,
    state,
  }
}

export default usePublicFileUpload
