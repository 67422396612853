import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import useContacts from '../../hooks/useContext/useContactEntries'
import ModalChecklist from '../ModalChecklist/ModalChecklist'

type Props = {
  label?: string
  value: string[]
  onChange: (value: string[]) => void
}

const ContactSelector: React.FC<Props> = ({ label, value, onChange }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [contacts] = useContacts()

  const resolveContactName = (uuid: string) => {
    if (!contacts) return null
    const contact = contacts.find((c) => c.uuid === uuid)
    return contact ? contact.firma : null
  }

  if (!contacts) return null
  return (
    <ModalChecklist
      label={label || t.UI.contactSelector.label}
      items={contacts.map((c) => c.uuid)}
      value={value}
      onChange={onChange}
      resolveName={resolveContactName}
    />
  )
}

export default ContactSelector
