import Checkbox from '../Checkbox/Checkbox'

type Props = {
  label?: string
  value: string[]
  items: {
    label: string
    uuid: string
  }[]
  onChange: (value: string[]) => void
  className?: string
}

const Checklist: React.FC<Props> = ({ label, value, items, onChange, className = '' }) => {
  const toggle = (uuid: string) => () => {
    if (value.includes(uuid)) {
      onChange(value.filter((v) => v !== uuid))
    } else {
      onChange([...value, uuid])
    }
  }

  return (
    <div className={`flex flex-col gap-2 ${className}`}>
      {!!label && <div className={'text-gray text-sm'}>{label}</div>}
      {items.map((item) => (
        <Checkbox label={item.label} checked={value.includes(item.uuid)} onChange={toggle(item.uuid)} key={item.uuid} />
      ))}
    </div>
  )
}

export default Checklist
