import { useSelector } from 'react-redux'
import Datepicker from 'react-tailwindcss-datepicker'
import props from '../../../redux/props'

export type DateRange = {
  startDate: Date | null
  endDate: Date | null
}

type Props = {
  id?: string
  label?: string
  className?: string
  invert?: boolean
  required?: boolean
  value: DateRange
  onChange: (value: DateRange) => void
  minDate?: boolean
  noLabel?: boolean
}

const DateRangePicker: React.FC<Props> = ({
  id,
  required,
  label,
  className = '',
  invert = false,
  value,
  onChange,
  minDate = false,
  noLabel = false,
}) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className={`w-full ${className}`}>
      {!noLabel && (
        <label htmlFor={id} className={invert ? 'text-white text-sm' : 'text-gray text-sm'}>
          {label || t.UI.dateRangePicker.label}
        </label>
      )}
      <Datepicker
        minDate={minDate ? new Date() : undefined}
        primaryColor={'yellow'}
        value={value}
        onChange={(newValue) => onChange(newValue)}
        inputClassName="border rounded-sm border-gold placeholder-gray w-full py-1 px-1 bg-white text-black focus:outline-none focus:shadow-outline"
        displayFormat="DD.MM.YYYY"
        i18n="de"
        required={required}
      />
    </div>
  )
}

export default DateRangePicker
