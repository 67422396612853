import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { ProtocolEntry } from '../../../../../types/Protocol'
import { getPrettyDate } from '../../../../../utility'

type Props = { data: ProtocolEntry }

const Entry: React.FC<Props> = ({ data }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const open = () => {
    window.location.href = `/dashboard/protocol/entry/${data.uuid}`
  }

  const getTeilnehmerList = () => {
    if (Array.isArray(data.teilnehmer) && data.teilnehmer.length > 0) {
      if (Array.isArray(data.teilnehmerZusaetzlich) && data.teilnehmerZusaetzlich.length > 0) {
        return data.teilnehmer
          .map((t) => `${t.vorname || ''} ${t.nachname || ''}`)
          .concat(data.teilnehmerZusaetzlich.map((t) => `${t.vorname || ''} ${t.nachname || ''}`))
          .join(', ')
      } else {
        return data.teilnehmer.map((t) => `${t.vorname || ''} ${t.nachname || ''}`).join(', ')
      }
    } else {
      return '-'
    }
  }

  return (
    <div onClick={open} className="flex w-full flex-col items-center gap-2 rounded-sm shadow-sm bg-white p-3 hover:bg-lightGray cursor-pointer">
      <div className="flex w-full gap-2">
        <div className="w-1/12">
          <div className="text-sm text-gray">{t.protocol.properties.number}</div>
          <div>{data.number}</div>
        </div>
        <div className="w-2/12">
          <div className="text-sm text-gray">{t.protocol.properties.project}</div>
          <div>{data?.project?.name || '-'}</div>
        </div>
        <div className="w-2/12">
          <div className="text-sm text-gray">{t.protocol.properties.mainTopic}</div>
          <div>{data.mainTopic || '-'}</div>
        </div>
        <div className="w-2/12">
          <div className="text-sm text-gray">{t.protocol.properties.besprechungsort}</div>
          <div>{data.besprechungsort || '-'}</div>
        </div>
        <div className="w-2/12">
          <div className="text-sm text-gray">{t.protocol.properties.date}</div>
          <div>{!!data.date ? getPrettyDate(data.date) : '-'}</div>
        </div>
        <div className="w-3/12">
          <div className="text-sm text-gray">{t.protocol.properties.teilnehmer}</div>
          <div>{getTeilnehmerList()}</div>
        </div>
      </div>
    </div>
  )
}

export default Entry
