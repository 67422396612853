import { useSelector } from 'react-redux'
import { useState } from 'react'
import useToast, { Type } from '../../../hooks/useToast'
import HasPermission from '../../HasPermission/HasPermission'
import Button from '../../../UI/Button/Button'
import Modal from '../../../UI/Modal/Modal'
import Input from '../../../UI/Input/Input'
import props from '../../../../redux/props'
import { functional } from '@think-internet/zeus-frontend-package'
import { Project } from '../../../../types/Project'
import CompanySelector from '../../../UI/CompanySelector/CompanySelector'
import Routes from '../../../../redux/routes'
import { FileSystemStructureType } from '../../../../types/FileSystemStructure/FileSystemStructure'
import Dropdown from '../../../UI/Dropdown/Dropdown'
import { defaultMailSignature, defaultSenderMail } from '../../../../utility'

type Payload = {
  name: string
  fileStructureType: FileSystemStructureType
  companyUUID?: string
  mail: string
  mailSignature: string
}

const Create: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const toast = useToast()
  const [data, setData] = useState<Payload>({
    name: '',
    fileStructureType: FileSystemStructureType.STANDARD,
    mail: defaultSenderMail,
    mailSignature: defaultMailSignature,
  })
  const [isOpen, setIsOpen] = useState(false)
  const projectCreate = functional.use(Routes.CREATE_PROJECT)
  const [loading, setLoading] = useState(false)

  const save = async () => {
    setLoading(true)
    const result: Project = await projectCreate(data)
    setLoading(false)
    if (!!result) {
      window.open(`/dashboard/projects/${result.uuid}`, '_self')
    } else {
      toast(t.project.create.error, Type.ERROR)
    }
  }

  const toggle = () => setIsOpen(!isOpen)

  const set = (key: keyof Payload) => (value: any) => setData({ ...data, [key]: value })

  if (!data) return null
  return (
    <HasPermission permission="createProject">
      <Button small onClick={toggle} text={t.project.create.cta} />
      <Modal loading={loading} title={t.project.create.cta} onClose={toggle} show={isOpen} onConfirm={save}>
        <div className="text-blue font-bold">{t.project.create.title.create}</div>
        <div className="flex flex-col gap-1">
          <CompanySelector value={data.companyUUID} onChange={set('companyUUID')} />
          <Input label={t.project.properties.name} value={data.name} onChange={set('name')} />
          <Dropdown
            label={t.project.create.fileSystemStructure.typeSelection}
            required
            items={Object.keys(FileSystemStructureType).map((l) => ({ label: t.project.create.fileSystemStructure[l], value: l }))}
            value={data.fileStructureType}
            onChange={set('fileStructureType')}
          />
        </div>
      </Modal>
    </HasPermission>
  )
}

export default Create
