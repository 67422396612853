import { useSelector } from 'react-redux'
import Container from '../../../UI/Container/Container'
import props from '../../../../redux/props'
import FullHeightWrapper from '../../../UI/FullHeightWrapper/FullHeightWrapper'
import useProjects from '../../../hooks/useContext/useProjects'
import Project from './Project'
import { useState } from 'react'
import { Project as ProjectType } from '../../../../types/Project'
import FilterConfigModal from '../FilterConfigModal/FilterConfigModal'
import Routes from '../../../../redux/routes'
import Export from '../../../UI/Export/Export'
import { TimetrackerFilter } from '../../../../types/Timetracker'

const Overview = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [projects] = useProjects()
  const [filterConfig, setFilterConfig] = useState<TimetrackerFilter>({ projectUUID: null, includeIsMeeting: true, includeNotBillable: true })

  const filter = (projects: ProjectType[]) => {
    return projects.filter((p) => !p.isArchived && (!filterConfig.projectUUID || p.uuid === filterConfig.projectUUID))
  }

  return (
    <FullHeightWrapper>
      <Container>
        <div className="flex flex-col gap-1 py-10">
          <div className="flex justify-between">
            <div className="flex justify-between">
              <div className="font-bold text-xl text-blue">{t.timetracking.title}</div>
            </div>
            <div className="flex gap-3 mt-3">
              <FilterConfigModal filterConfig={filterConfig} setFilterConfig={setFilterConfig} />
              <Export
                filter={filterConfig}
                context={{
                  fileName: 'Zeiterfassung',
                  headline: 'Zeiterfassung',
                }}
                route={Routes.GENERATE_TIMETRACKING_EXPORT}
                translationProp="timetracking"
                availableProperties={[
                  'project.name',
                  'member.firstname',
                  'member.lastname',
                  'area',
                  'description',
                  'hours',
                  'date',
                  'calendarWeek',
                  'notBillable',
                  'isMeeting',
                ]}
                preSelectedProperties={['project.name', 'area', 'description', 'hours', 'date', 'calendarWeek']}
              />
            </div>
          </div>
          <div className="flex flex-col gap-10 mt-5">
            {Array.isArray(projects) &&
              filter(projects).map((project) => <Project key={project.uuid} project={project} filterConfig={filterConfig} />)}
          </div>
        </div>
      </Container>
    </FullHeightWrapper>
  )
}

export default Overview
