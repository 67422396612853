import { useSelector } from 'react-redux'
import { useState } from 'react'
import { PencilIcon, PlusIcon } from '@heroicons/react/24/solid'
import { Ansprechpartner } from '../../../../types/Contact'
import props from '../../../../redux/props'
import HasPermission from '../../HasPermission/HasPermission'
import Tooltip from '../../../UI/Tooltip/Tooltip'
import Modal from '../../../UI/Modal/Modal'
import Input from '../../../UI/Input/Input'
import { getUUID } from '../../../../utility'

type Props = {
  initData?: Ansprechpartner
  update?: (data: Ansprechpartner) => void
  add?: (data: Ansprechpartner) => void
  remove?: () => void
}

const Upsert: React.FC<Props> = ({ initData, update, add, remove }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [data, setData] = useState<Ansprechpartner>(initData || { uuid: getUUID() })
  const [isOpen, setIsOpen] = useState(false)

  const save = async () => {
    if (!!initData) {
      update(data)
    } else {
      add(data)
    }
    toggle()
    setData(initData || { uuid: getUUID() })
  }

  const toggle = () => setIsOpen(!isOpen)

  const set = (key: keyof Ansprechpartner) => (value: any) => setData({ ...data, [key]: value })

  if (!data) return null
  return (
    <HasPermission permission="createContact">
      {!initData && (
        <div onClick={toggle}>
          <Tooltip className="ml-auto mt-1" text={t.generic.add}>
            <PlusIcon className="w-5 stroke-blue" />
          </Tooltip>
        </div>
      )}
      {!!initData && (
        <div onClick={toggle}>
          <Tooltip text={t.generic.edit}>
            <PencilIcon className="w-5 stroke-blue" />
          </Tooltip>
        </div>
      )}
      <Modal noForm onClose={toggle} show={isOpen} onConfirm={save} onRemove={remove}>
        <div className="text-blue font-bold">
          {!!initData ? t.contact.ansprechpartner.upsert.title.update : t.contact.ansprechpartner.upsert.title.create}
        </div>
        <Input label={t.contact.properties.vorname} value={data.vorname} onChange={set('vorname')} />
        <Input label={t.contact.properties.nachname} value={data.nachname} onChange={set('nachname')} />
        <Input label={t.contact.properties.position} value={data.position} onChange={set('position')} />
        <Input label={t.contact.properties.telefon} value={data.telefon} onChange={set('telefon')} />
        <Input label={t.contact.properties.mobil} value={data.mobil} onChange={set('mobil')} />
        <Input label={t.contact.properties.mail} value={data.mail} onChange={set('mail')} />
      </Modal>
    </HasPermission>
  )
}

export default Upsert
