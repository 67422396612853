import { Navigate, Route, Routes } from 'react-router-dom'
import Overview from './Overview/Overview'
import Entry from './Entry/Entry'

const Protocol = () => {
  return (
    <Routes>
      <Route path={'overview'} element={<Overview />} />
      <Route path={'entry/:uuid'} element={<Entry />} />

      <Route path={'*'} element={<Navigate replace to="overview" />} />
    </Routes>
  )
}

export default Protocol
