import { Navigate, Route, Routes } from 'react-router-dom'
import Overview from './Overview/Overview'
import View from './Project/View/View'

const Protocol = () => {
  return (
    <Routes>
      <Route path={'overview'} element={<Overview />} />
      <Route path={'project/:projectUUID'} element={<View />} />

      <Route path={'*'} element={<Navigate replace to="overview" />} />
    </Routes>
  )
}

export default Protocol
