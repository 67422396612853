import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { Ansprechpartner } from '../../../../types/Contact'
import Upsert from './Upsert'
import { useEffect } from 'react'

type Props = {
  value: Ansprechpartner[]
  onChange: (value: Ansprechpartner[]) => void
}

const AnsprechpartnerManager: React.FC<Props> = ({ value, onChange }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  useEffect(() => {
    if (!Array.isArray(value)) {
      onChange([])
    }
  }, [value, onChange])

  const add = (data: Ansprechpartner) => onChange([...value, data])
  const update = (index: number) => (data: Ansprechpartner) => {
    const newValue = [...value]
    newValue[index] = data
    onChange(newValue)
  }
  const remove = (index: number) => () => {
    const newValue = [...value]
    newValue.splice(index, 1)
    onChange(newValue)
  }

  return (
    <>
      <label className={'text-gray text-sm'}>{t.contact.properties.ansprechpartnerList}</label>
      <div className="flex flex-col gap-1">
        {Array.isArray(value) &&
          value.map((a, i) => (
            <div className="flex justify-between" key={i}>
              {`${a.vorname || ''} ${a.nachname || ''}`}
              <Upsert initData={a} update={update(i)} remove={remove(i)} />
            </div>
          ))}
      </div>
      <Upsert add={add} />
    </>
  )
}

export default AnsprechpartnerManager
