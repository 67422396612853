import { useSelector } from 'react-redux'
import props from '../../../../../../redux/props'

const TableHeader = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  return (
    <div className="py-1">
      <div className="flex items-center">
        {/* <div className="w-[50px] break-words text-sm shrink-0 grow-0">{t.timetracking.properties.laufendeNummer}</div> */}
        <div className="w-[60px] break-words text-sm shrink-0 grow-0 font-bold px-1">{t.timetracking.properties.laufendeNummer}</div>
        <div className="w-1/12 break-words text-sm shrink-0 grow-0 font-bold px-1">{t.timetracking.properties.calendarWeek}</div>
        <div className="w-1/12 break-words text-sm shrink-0 grow-0 font-bold px-1">{t.timetracking.properties.hours}</div>
        <div className="w-1/12 break-words text-sm shrink-0 grow-0 font-bold px-1">{t.timetracking.properties.date}</div>
        <div className="w-2/12 break-words text-sm shrink-0 grow-0 font-bold px-1">{t.timetracking.properties.name}</div>
        <div className="w-2/12 break-words text-sm shrink-0 grow-0 font-bold px-1">{t.timetracking.properties.area}</div>
        <div className="w-1/12 break-words text-sm shrink-0 grow-0 font-bold px-1">{t.timetracking.properties.billable}</div>
        <div className="w-1/12 break-words text-sm shrink-0 grow-0 font-bold px-1">{t.timetracking.properties.isMeeting}</div>
        <div className="w-3/12 break-words text-sm shrink-0 grow-0 font-bold px-1">{t.timetracking.properties.description}</div>
      </div>
    </div>
  )
}

export default TableHeader
