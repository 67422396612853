import Dropdown from '../Dropdown/Dropdown'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import useMemberContext from '../../hooks/useContext/useMemberContext'

type Props = {
  value: string | string[]
  onChange: (value: string | string[]) => void
  required?: boolean
  multiple?: boolean
}

const CompanySelector: React.FC<Props> = ({ value, onChange, multiple = false, required = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [context] = useMemberContext()

  if (!context) return null
  return (
    <Dropdown
      label={t.UI.companySelector.label}
      required={required}
      items={context.companies.map((c) => ({ label: c.name, value: c.uuid }))}
      value={value}
      onChange={onChange}
      multiple={multiple}
    />
  )
}

export default CompanySelector
