import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Button from '../Button/Button'
import { useState } from 'react'
import Modal from '../Modal/Modal'
import { functional } from '@think-internet/zeus-frontend-package'
import { ExportType } from '../../../types/Export'
import useToast from '../../hooks/useToast'
import Dropdown from '../Dropdown/Dropdown'
import Checklist from '../Checklist/Checklist'

export type ExportProps = {
  title?: string
  filter: object
  context?: object
  route: string
  availableProperties?: string[]
  preSelectedProperties?: string[]
  translationProp: string
  small?: boolean
  exportTypes?: ExportType[]
  children?: React.ReactNode
}

const Export: React.FC<ExportProps> = ({
  title,
  filter,
  context,
  route,
  availableProperties,
  preSelectedProperties,
  translationProp,
  small,
  exportTypes = null,
  children = null,
}) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState(false)
  const startExport = functional.use(route)
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const [exportType, setExportType] = useState(ExportType.PDF)
  const [exportProperties, setExportProperties] = useState<string[]>([...(preSelectedProperties || [])])

  const toggle = () => setOpen(!open)

  const start = async () => {
    setLoading(true)
    const newContext = { ...(context || {}), properties: exportProperties }
    const fileURL = await startExport({ filter, context: newContext, exportType })
    setLoading(false)
    if (!!fileURL) {
      window.open(fileURL, '_blank')
    } else {
      toast(t.UI.export.error)
    }
  }

  const getAvailableProperties = () => {
    return availableProperties.map((prop) => {
      if (prop.includes('.')) {
        const [parent, child] = prop.split('.')
        return {
          label: `${t[translationProp].properties[parent]} - ${t[translationProp].properties[child]}`,
          uuid: prop,
        }
      }
      return {
        label: t[translationProp].properties[prop],
        uuid: prop,
      }
    })
  }

  return (
    <div>
      <Button small={small} text={title || t.UI.export.cta} onClick={toggle} />
      <Modal onClose={toggle} show={open} loading={loading} onConfirm={start} title={title || t.UI.export.title}>
        <div className="flex flex-col gap-3">
          <Dropdown
            label={t.UI.export.chooseFormat}
            required
            items={(exportTypes || Object.keys(ExportType)).map((e) => ({ label: t.UI.export.type[e], value: e }))}
            value={exportType}
            onChange={setExportType}
          />
          {exportType === ExportType.CSV && Array.isArray(availableProperties) && (
            <Checklist label={t.UI.export.chooseProps} value={exportProperties} items={getAvailableProperties()} onChange={setExportProperties} />
          )}
          {!!children && children}
        </div>
      </Modal>
    </div>
  )
}

export default Export
