import { useSelector } from 'react-redux'
import { File } from 'lucide-react'
import { useState } from 'react'
import { CloudArrowUpIcon, TrashIcon } from '@heroicons/react/24/outline'
import props from '../../../redux/props'
import usePublicFileUpload, { FileUploadStatus } from './usePublicFileUpload'
import Button from '../Button/Button'
import Modal from '../Modal/Modal'
import Progress from '../Progress/Progress'
import DragAndDrop from '../DragAndDrop/DragAndDrop'
import { PublicFile } from '../../../types/Generic'

type Props = {
  folder: string
  addFiles: (files: PublicFile[]) => void
}

const PublicFileUpload: React.FC<Props> = ({ folder, addFiles }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [isOpen, setIsOpen] = useState(false)
  const [allFiles, setAllFiles] = useState<File[]>([])
  const { fileUpload, state } = usePublicFileUpload()

  const run = async () => {
    const files = await fileUpload(folder, allFiles)
    setAllFiles([])
    toggle()
    addFiles(files)
  }

  const toggle = () => setIsOpen(!isOpen)

  const process = (files: File[]) => {
    setAllFiles([...allFiles, ...files])
  }

  const removeFile = (name: string) => () => {
    setAllFiles(allFiles.filter((file) => file.name !== name))
  }

  const isReady = state.status === FileUploadStatus.READY

  return (
    <div>
      <Button onClick={toggle} text={t.UI.publicFileUpload.cta} />
      <Modal title={t.UI.publicFileUpload.title} onClose={toggle} noClose={!isReady} show={isOpen} onConfirm={isReady && run}>
        {!isReady && (
          <div className="mt-3">
            <div className="text-center text-sm whitespace-nowrap truncate">{`${state.workingOn}`}</div>
            <div className="my-1">
              <Progress progress={state.progress} stayVisible />
            </div>
            <div className="text-center text-sm">{`${state.remaining} / ${state.total}`}</div>
          </div>
        )}
        {isReady && (
          <>
            <DragAndDrop multiple onFiles={process}>
              <div className="mt-3 flex items-center flex-col">
                <CloudArrowUpIcon className="stroke-blue hover:scale-105 transition-all w-20" />
              </div>
            </DragAndDrop>
            {Array.isArray(allFiles) && allFiles.length > 0 && (
              <div className="divide-y mt-3 overflow-hidden w-full">
                {allFiles.map((file) => (
                  <div key={file.name} className="text-sm flex items-center w-full gap-3">
                    <div className="w-full whitespace-nowrap truncate">{file.name}</div>
                    <div className="w-fit cursor-pointer" onClick={removeFile(file.name)}>
                      <TrashIcon className="h-4 stroke-blue" />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </Modal>
    </div>
  )
}

export default PublicFileUpload
