import { Navigate, Route, Routes } from 'react-router-dom'
import List from './List/List'
import Update from './Update/Update'

const Protocol = () => {
  return (
    <Routes>
      <Route path={'list'} element={<List />} />
      <Route path={':uuid'} element={<Update />} />

      <Route path={'*'} element={<Navigate replace to="list" />} />
    </Routes>
  )
}

export default Protocol
