import { useSelector } from 'react-redux'
import Button from '../../../../UI/Button/Button'
import Modal from '../../../../UI/Modal/Modal'
import DateRangePicker from '../../../../UI/DateRangePicker/DateRangePicker'
import ProjectSelector from '../../../../UI/ProjectSelector/ProjectSelector'
import { useState } from 'react'
import props from '../../../../../redux/props'
import { Filter } from '../Overview'

const FilterConfigModal = ({ filterConfig, setFilterConfig }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [isOpen, setIsOpen] = useState(false)
  const [internalFilterConfig, setInternalFilterConfig] = useState({ ...filterConfig })

  const updateFilterConfig = (key: keyof Filter) => (value: any) => {
    setInternalFilterConfig((prev) => ({ ...prev, [key]: value }))
  }

  const confirm = () => {
    setFilterConfig(internalFilterConfig)
    toggle()
  }

  const toggle = () => setIsOpen(!isOpen)

  return (
    <div>
      <Button text={t.timetracking.filter.cta} onClick={toggle} />
      <Modal title={t.timetracking.filter.modalTitle} show={isOpen} onClose={toggle} onConfirm={confirm}>
        <div className="flex flex-col gap-2">
          <ProjectSelector value={internalFilterConfig.projectUUID} onChange={updateFilterConfig('projectUUID')} />
          <DateRangePicker
            label={t.timetracking.filter.dateRange}
            value={internalFilterConfig.dateRange}
            onChange={updateFilterConfig('dateRange')}
          />
        </div>
      </Modal>
    </div>
  )
}

export default FilterConfigModal
