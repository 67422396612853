import props from '../../../redux/props'
import { ResponseFunctional } from './types'
import { MemberContext } from '../../../types/Context'
import useAnyFunctional from './useAnyFunctional'
import Routes from '../../../redux/routes'

const useMemberContext = (enableFetching: boolean = false): ResponseFunctional<MemberContext> =>
  useAnyFunctional<MemberContext>(props.MEMBER_CONTEXT, Routes.GET_MEMBER_CONTEXT, null, enableFetching)

export default useMemberContext
