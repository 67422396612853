import { useSelector } from 'react-redux'
import useContacts from '../../hooks/useContext/useContactEntries'
import props from '../../../redux/props'
import ModalCategoryChecklist, { ChecklistCategory, ValueItem } from '../ModalCategoryChecklist/ModalCategoryChecklist'
import { Ansprechpartner, Contact } from '../../../types/Contact'

export type AnsprechartnerWithContact = Ansprechpartner & { contactUUID: string; contact?: Contact }

type Props = {
  label?: string
  value: AnsprechartnerWithContact[]
  onChange: (value: AnsprechartnerWithContact[]) => void
  noLabel?: boolean
  noPreview?: boolean
}

const ContactAnsprechpartnerSelector: React.FC<Props> = ({ label, value, onChange, noPreview = false, noLabel = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [contacts] = useContacts()

  const getCategories = (): ChecklistCategory[] => {
    if (!contacts) return []
    return contacts
      .filter((c) => !!c.ansprechpartnerList && c.ansprechpartnerList.length > 0)
      .map((c) => ({
        name: c.firma,
        uuid: c.uuid,
        items: c.ansprechpartnerList.map((a) => ({
          label: `${a.vorname || ''} ${a.nachname || ''}`,
          uuid: a.uuid,
        })),
      }))
  }

  const resolveAnsprechpartner = (item: ValueItem) => {
    if (!contacts) return null
    const contactIndex = contacts.findIndex((c) => c.uuid === item.categoryUUID)
    if (contactIndex > -1) {
      const ap = contacts[contactIndex].ansprechpartnerList.find((a) => a.uuid === item.itemUUID)
      return ap ? `${ap.vorname || ''} ${ap.nachname || ''}` : null
    } else {
      return null
    }
  }

  const getValue = (): ValueItem[] => {
    return value.map((v) => ({
      categoryUUID: v.contactUUID,
      itemUUID: v.uuid,
    }))
  }

  const internalOnChange = (value: ValueItem[]) => {
    onChange(
      value
        .map((v) => {
          const contactIndex = contacts.findIndex((c) => c.uuid === v.categoryUUID)
          if (contactIndex > -1) {
            const ap = contacts[contactIndex].ansprechpartnerList.find((a) => a.uuid === v.itemUUID)
            if (!ap) return null
            return {
              ...ap,
              contactUUID: v.categoryUUID,
              contact: contacts[contactIndex],
            }
          } else {
            return null
          }
        })
        .filter((v) => !!v),
    )
  }

  if (!contacts) return null
  return (
    <ModalCategoryChecklist
      label={!noLabel ? label || t.UI.contactAnsprechpartnerSelector.label : null}
      items={getCategories()}
      value={getValue()}
      onChange={internalOnChange}
      resolveName={!noPreview && resolveAnsprechpartner}
    />
  )
}

export default ContactAnsprechpartnerSelector
