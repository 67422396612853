import { useSelector } from 'react-redux'

import Entry from './Entry/Entry'
import SimpleList from '../../../UI/SimpleList/SimpleList'
import FullHeightWrapper from '../../../UI/FullHeightWrapper/FullHeightWrapper'
import { ProtocolEntry } from '../../../../types/Protocol'
import useProtocolEntries from '../../../hooks/useContext/useProtocolEntries'
import props from '../../../../redux/props'
import Create from './Create/Create'
import Container from '../../../UI/Container/Container'
import FilterConfigModal from './FilterConfigModal/FilterConfigModal'
import { DateRange } from '../../../UI/DateRangePicker/DateRangePicker'
import { useState } from 'react'
import { fitsInDateRange } from '../../../../utility'

export type Filter = {
  projectUUID?: string
  dateRange?: DateRange
}

const Overview = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [protocolEntries] = useProtocolEntries()
  const [filterConfig, setFilterConfig] = useState<Filter>({})

  const filter = (search: string) => (protocolEntry: ProtocolEntry) => {
    if (!protocolEntry.project || protocolEntry.project.isArchived) return false
    const projectCheck = !!filterConfig.projectUUID ? protocolEntry.project.uuid === filterConfig.projectUUID : true
    const dateCheck = !!filterConfig.dateRange ? fitsInDateRange(new Date(protocolEntry.date), filterConfig.dateRange) : true
    if (search.length > 0) {
      const number = protocolEntry.number.toString() === search.toLowerCase()
      const besprechungsort = !!protocolEntry.besprechungsort ? protocolEntry.besprechungsort.toLowerCase().includes(search.toLowerCase()) : false
      const project = !!protocolEntry?.project?.name ? protocolEntry.project.name.toLowerCase().includes(search.toLowerCase()) : false
      return (number || besprechungsort || project) && projectCheck && dateCheck
    } else {
      return projectCheck && dateCheck
    }
  }

  return (
    <FullHeightWrapper>
      <Container>
        <div className="py-10">
          <SimpleList
            listClassName="flex flex-col gap-1"
            itemClassName="border-none"
            title={t.protocol.overview.title}
            data={protocolEntries}
            filter={filter}
            Component={Entry}
            Upsert={Create}
            customFilter={[<FilterConfigModal filterConfig={filterConfig} setFilterConfig={setFilterConfig} />]}
          />
        </div>
      </Container>
    </FullHeightWrapper>
  )
}

export default Overview
