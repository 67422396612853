type Props = {
  title: string
  subline?: string
}

const Headline: React.FC<Props> = ({ title, subline }) => {
  return (
    <div>
      <div className="font-bold text-xl text-blue">{title}</div>
      {!!subline && <div className="mt-1 text-blue">{subline}</div>}
    </div>
  )
}

export default Headline
