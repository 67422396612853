import { useState } from 'react'
import Modal from '../../../UI/Modal/Modal'
import Input from '../../../UI/Input/Input'
import Tooltip from '../../../UI/Tooltip/Tooltip'
import props from '../../../../redux/props'
import { useSelector } from 'react-redux'
import { PencilIcon } from '@heroicons/react/24/solid'
import { getPrettyDate } from '../../../../utility'

type Props = {
  label?: string
  initValue: any
  onChange: (value: any) => void
  type?: string
  boldValue?: boolean
  noLabel?: boolean
  onRemove?: () => void
}

const EditInput: React.FC<Props> = ({ label, initValue, onChange, type, boldValue = false, noLabel = false, onRemove }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(initValue)

  const toggle = () => setOpen(!open)

  const save = () => {
    onChange(value)
    toggle()
  }

  const getValue = () => {
    if (type === 'date' && !!initValue) {
      return getPrettyDate(initValue)
    } else if (type === 'time' && !!initValue) {
      const timeStringSplit = initValue.split(':')
      return `${timeStringSplit[0]}:${timeStringSplit[1]}`
    }
    return initValue
  }

  return (
    <div className="w-full">
      <div className="flex gap-2 items-center justify-between">
        {!!label && !noLabel && <div className="font-bold w-1/2">{`${label}:`}</div>}
        <div className={`flex gap-2 items-center ${!!label && 'w-1/2'} ${boldValue && 'font-bold text-md'}`}>
          <div>{getValue() || '-'}</div>
          <Tooltip text={t.generic.edit}>
            <PencilIcon className="w-4 fill-blue" onClick={toggle} />
          </Tooltip>
        </div>
      </div>
      <Modal onClose={toggle} show={open} onConfirm={save} onRemove={onRemove}>
        <div className="text-blue font-bold">{label}</div>
        <Input type={type} value={value} onChange={setValue} />
      </Modal>
    </div>
  )
}

export default EditInput
