import Routes from '../../../redux/routes'
import Export from '../Export/Export'

type Props = {
  title?: string
  filter: {
    projectUUID?: string
    gewerk?: string
  }
  context: {
    fileName: string
    headline: string
    secondHeadline?: string
  }
  small?: boolean
}

const ContactExport: React.FC<Props> = ({ title, filter, context, small }) => {
  return (
    <Export
      title={title}
      small={small}
      filter={filter}
      context={context}
      availableProperties={[
        'gewerk',
        'firma',
        'adresse',
        'telefon',
        'mobil',
        'mail',
        'kuerzel',
        'ansprechpartnerList.vorname',
        'ansprechpartnerList.nachname',
        'ansprechpartnerList.position',
        'ansprechpartnerList.telefon',
        'ansprechpartnerList.mobil',
        'ansprechpartnerList.mail',
        'bemerkung',
        'bewertung',
      ]}
      preSelectedProperties={[
        'gewerk',
        'firma',
        'adresse',
        'telefon',
        'mobil',
        'mail',
        'ansprechpartnerList.vorname',
        'ansprechpartnerList.nachname',
        'ansprechpartnerList.position',
        'ansprechpartnerList.telefon',
        'ansprechpartnerList.mobil',
        'ansprechpartnerList.mail',
      ]}
      route={Routes.GENERATE_CONTACT_EXPORT}
      translationProp="contact"
    />
  )
}

export default ContactExport
