import { useSelector } from 'react-redux'
import globalProps from '../../../redux/props'
import React, { useState } from 'react'
import Refresh from './Refresh'
import Loading from '../Loading/Loading'
import Search from './Search'
import styled from 'styled-components'
import Headline from '../Headline/Headline'

type Props = {
  title?: string
  data: any[]
  pull?: () => void
  filter: (search: string) => (item: any) => boolean
  Upsert?: React.FC<{ initData?: any; pullList?: () => void }>
  Component: React.FC<{ data: any; pullList: () => void }>
  Header?: React.FC
  enableBlockView?: boolean
  listClassName?: string
  itemClassName?: string
  customFilter?: React.ReactNode[]
  exportComponent?: React.ReactNode
}

const SimpleList: React.FC<Props> = ({
  title,
  data,
  pull,
  filter,
  Upsert,
  Component,
  Header,
  enableBlockView,
  customFilter,
  exportComponent,
  listClassName = '',
  itemClassName = '',
}) => {
  const t = useSelector((s) => s[globalProps.TRANSLATION])
  const [search, setSearch] = useState<string>('')

  const ComponentWrapper = styled.div`
    ${enableBlockView &&
    `
      width: calc(50% - 10px);
    `}
  `

  return (
    <div className="flex flex-col gap-1">
      <div className="flex gap-3 justify-between items-center">
        {!!title ? <Headline title={title} /> : <div />}
        <div className="flex flex-row gap-3 items-center">
          {!!customFilter && customFilter}
          {!!exportComponent && exportComponent}
          {!!Upsert && (
            <div className="shrink-0">
              <Upsert pullList={pull} />
            </div>
          )}
          <Search setSearch={setSearch} search={search} />
          {!!pull && <Refresh pull={pull} />}
        </div>
      </div>
      <Loading loading={data} />
      {Array.isArray(data) && (
        <>
          <div className="mt-3">
            {data.filter(filter(search)).length}
            {data.filter(filter(search)).length === 1 ? t.UI.simpleList.resultAmountSuffixSingular : t.UI.simpleList.resultAmountSuffixPlural}
          </div>
          {Header && !enableBlockView && <Header />}
          {data.length > 0 && (
            <div className={enableBlockView ? `flex flex-wrap gap-[20px] mt-3 ${listClassName}` : listClassName}>
              {data.filter(filter(search)).map((item) => {
                return (
                  <ComponentWrapper
                    className={!enableBlockView ? `border-t border-graySecondary first:border-none py-1 ${itemClassName}` : itemClassName}
                    key={item.uuid}
                  >
                    <Component data={item} pullList={pull} />
                  </ComponentWrapper>
                )
              })}
            </div>
          )}
          {data.length === 0 && <div className="text-gray text-center text-sm mt-3">{t.UI.simpleList.noData}</div>}
        </>
      )}
    </div>
  )
}

export default SimpleList
