import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import FullHeightWrapper from '../../UI/FullHeightWrapper/FullHeightWrapper'
import LargeContainer from '../../UI/Container/LargeContainer'
import useContacts from '../../hooks/useContext/useContactEntries'
import Upsert from './Entry/Upsert'
import Entry from './Entry/Entry'
import SimpleList from '../../UI/SimpleList/SimpleList'
import { Contact as ContactType } from '../../../types/Contact'
import GewerkSelector from '../../UI/GewerkSelector/GewerkSelector'
import { useState } from 'react'
import ProjectSelector from '../../UI/ProjectSelector/ProjectSelector'
import ContactExport from '../../UI/ContactExport/ContactExport'
import useMemberContext from '../../hooks/useContext/useMemberContext'

const Contact = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [contacts] = useContacts()
  const [selectedGewerk, setSelectedGewerk] = useState<string | string[]>('')
  const [selectedProject, setSelectedProject] = useState<string | string[]>('')
  const [context] = useMemberContext()

  const filterByPermission = (contact: ContactType) => {
    if (!contact.isSensitive) return true
    if (!context || !context.permissions) return false
    return context.permissions.manageSensitiveContactData
  }

  const filterByGewerk = (contact: ContactType) => {
    if (!!selectedGewerk && selectedGewerk.length > 0) {
      return contact.gewerk === selectedGewerk
    } else {
      return true
    }
  }

  const filterByProject = (contact: ContactType) => {
    if (!!selectedProject && selectedProject.length > 0 && !Array.isArray(selectedProject)) {
      return contact.projectUUIDList.includes(selectedProject)
    } else {
      return true
    }
  }

  const filter = (search: string) => (contact: ContactType) => {
    if (search.length > 0) {
      const firma = !!contact.firma ? contact.firma.toLowerCase().includes(search.toLowerCase()) : false
      const ansprechpartner = Array.isArray(contact.ansprechpartnerList)
        ? contact.ansprechpartnerList.some(
            (a) =>
              `${a.vorname || ''} ${a.nachname || ''}`.toLowerCase().includes(search.toLowerCase()) ||
              (!!a.mail && a.mail.toLowerCase().includes(search.toLowerCase())),
          )
        : false
      const mail = !!contact.mail ? contact.mail.toLowerCase().includes(search.toLowerCase()) : false
      const gewerk = !!contact.gewerk ? contact.gewerk.toLowerCase().includes(search.toLowerCase()) : false
      const projects = !!contact.projects ? contact.projects.some((p) => p.name.toLowerCase().includes(search.toLowerCase())) : false
      const bemerkung = !!contact.bemerkung ? contact.bemerkung.toLowerCase().includes(search.toLowerCase()) : false
      return (
        filterByGewerk(contact) &&
        filterByProject(contact) &&
        filterByPermission(contact) &&
        (firma || ansprechpartner || mail || gewerk || projects || bemerkung)
      )
    } else {
      return filterByGewerk(contact) && filterByProject(contact) && filterByPermission(contact)
    }
  }

  return (
    <FullHeightWrapper>
      <LargeContainer>
        <div className="py-10">
          <SimpleList
            listClassName="flex flex-col gap-5"
            itemClassName="border-none"
            title={t.contact.title}
            data={contacts}
            filter={filter}
            Component={Entry}
            Upsert={Upsert}
            customFilter={[
              <GewerkSelector disableLabel value={selectedGewerk} onChange={setSelectedGewerk} />,
              <ProjectSelector useAllProjects disableLabel value={selectedProject} onChange={setSelectedProject} />,
            ]}
            exportComponent={
              <ContactExport
                filter={{ projectUUID: selectedProject as string, gewerk: selectedGewerk as string }}
                context={{
                  headline: 'Kontaktliste',
                  fileName: `Kontaktliste`,
                }}
              />
            }
          />
        </div>
      </LargeContainer>
    </FullHeightWrapper>
  )
}

export default Contact
