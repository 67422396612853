import { crud } from '@think-internet/zeus-frontend-package'

const props = {
  ...crud.Features,

  // frontend only features
  UNIQUE_GEWERKE: 'UNIQUE_GEWERKE',

  // generic features
  MEMBER: 'MEMBER',
  ACCESS: 'ACCESS',
  PDF_INTERPRETATION: 'PDF_INTERPRETATION',
  COMPANY: 'COMPANY',
  MEMBER_CONTEXT: 'MEMBER_CONTEXT',
  PROJECT: 'PROJECT',

  // timetracker features
  TIMETRACKER_ENTRY: 'TIMETRACKER_ENTRY',

  // file explorer features
  FILE_SYSTEM: 'FILE_SYSTEM',

  // contact features
  CONTACT_ENTRY: 'CONTACT_ENTRY',

  // protocol features
  PROTOCOL_ENTRY: 'PROTOCOL_ENTRY',
}

export default props
